import { useState } from "react";
import { Box, Flex, Center, HStack, Text, Link } from "@chakra-ui/react";
import { useSwipeable } from "react-swipeable";

import { trackEvent } from "dapp-sdk-v2";

import { ON_BLOG_CLICK } from "@constants/analytics";
import BaseImage from "dapp-sdk-v2/components/Image";

type Props = {
  blogs: Array<{
    id: string;
    feature_image: string;
    title: string;
    reading_time: string;
    updated_at: string;
    slug: string;
  }>;
};

const Carousel = ({ blogs }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSwipeRight = () => {
    setCurrentIndex((prev) => {
      if (prev === blogs.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };

  const handleSwipeLeft = () => {
    setCurrentIndex((prev) => {
      if (prev === 0) {
        return blogs.length - 1;
      } else {
        return prev - 1;
      }
    });
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeRight,
    onSwipedRight: handleSwipeLeft,
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <Box mt={12} textAlign="center" {...handlers}>
      <Center>
        {blogs.map(
          (
            { id, title, reading_time, updated_at, feature_image, slug },
            index
          ) => (
            <Link
              href={`/blogs/${slug}`}
              isExternal
              rel="canonical"
              key={id}
              onClick={() =>
                trackEvent(ON_BLOG_CLICK, {
                  slug: slug,
                })
              }
            >
              <Box
                w={{ base: 328, lg: 371 }}
                h={{ base: 323, lg: 356 }}
                borderRadius={16}
                overflow="hidden"
                display={currentIndex === index ? "block" : "none"}
                animation="fadeIn 0.5s"
              >
                <BaseImage
                  width={328}
                  height={185}
                  src={feature_image}
                  alt={title}
                />
                <Flex
                  h={154}
                  display="flex"
                  flexDir="column"
                  justifyContent="space-between"
                  textAlign="left"
                  bg="#E6F8EF"
                  p={6}
                  borderRadius={16}
                  borderTopRadius={0}
                >
                  <Text fontSize="20px" fontWeight={600}>
                    {title}
                  </Text>
                  <Text fontSize="14px" fontWeight={500}>
                    {reading_time} mins / updated on{" "}
                    {new Date(updated_at).toDateString()}
                  </Text>
                </Flex>
              </Box>
            </Link>
          )
        )}
      </Center>
      <HStack gap={{ base: "10px", lg: 2.5 }} mt={6} w="full" justify="center">
        {blogs.map((blog, index) => (
          <Box
            key={`indicator-${blog.id}`}
            bg={index === currentIndex ? "primary" : "seperator"}
            w={2}
            h={2}
            borderRadius="50%"
          />
        ))}
      </HStack>
    </Box>
  );
};

export default Carousel;
